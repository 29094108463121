function IconLocationZone(props) {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19.432 30" {...props}>
			<g id="Groupe_4772" transform="translate(-1020.719 -1074.262)">
				<path
					fill="currentColor"
					d="M1032.94 1100.108a.8.8 0 0 0 .629.367.727.727 0 0 0 .63-.367l7.129-11.9a9.257 9.257 0 0 0 1.31-4.721 9.16 9.16 0 0 0-9.094-9.225v.01h-.077a9.239 9.239 0 0 0-9.225 9.225 9.057 9.057 0 0 0 1.362 4.819zm.525-19.7a3.092 3.092 0 0 1 3.091 3.037v.055a3.132 3.132 0 0 1-3.092 3.092 3.092 3.092 0 0 1 0-6.184z"
					transform="translate(-3.045)"
				/>
				<path
					fill="currentColor"
					d="M1040.151 1246.3c-.006-1.623-2.015-2.814-5.657-3.355l-.276-.041-1.276 1.671.812.105c3.282.424 4.343 1.275 4.343 1.621 0 .554-2.686 1.907-7.664 1.907s-7.664-1.354-7.664-1.907c0-.365 1.117-1.306 4.436-1.722l.835-.1-1.326-1.669-.269.039c-3.639.526-5.726 1.786-5.726 3.457 0 2.188 3.993 3.717 9.71 3.719q.307.01.615.01a18.935 18.935 0 0 0 7.351-1.491 2.681 2.681 0 0 0 1.751-2.175v-.032z"
					transform="translate(0 -145.77)"
				/>
			</g>
		</svg>
	);
}

export default IconLocationZone;
