import { useState, useCallback } from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import classNames from "classnames";
import { usePopper } from "react-popper";
import "./Popper.scss";

const usePopperBehavior = () => {
	const [anchorEl, setAnchorEl] = useState(null);

	const handleOpen = e => {
		if (e?.target) {
			setAnchorEl(e?.target);
		}
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	return { open: Boolean(anchorEl), anchorEl, handleOpen, handleClose };
};

const Popper = ({ behavior, className, placement, modifiers = () => {}, children }) => {
	const { anchorEl, handleClose } = behavior;
	const [arrowElement, setArrowElement] = useState(null);
	const [popperElement, setPopperElement] = useState(null);
	const { styles, attributes, state } = usePopper(anchorEl, popperElement, {
		placement: placement || "bottom", //https://popper.js.org/docs/v2/constructors/#options
		modifiers: [
			{ name: "arrow", options: { element: arrowElement, placement } },
			{
				name: "offset",
				options: {
					offset: [15, 15],
				},
			},
			{
				name: "flip",
				options: {
					fallbackPlacements: ["top"],
				},
			},
			{ ...modifiers },
		],
	});

	const handlePopperClose = useCallback(e => {
		e.preventDefault();
		handleClose();
	}, []);

	if (anchorEl) {
		return ReactDOM.createPortal(
			<>
				<div className="popper__overlay" onClick={handlePopperClose} />
				<div
					className={classNames(className, "popper")}
					ref={setPopperElement}
					style={styles.popper}
					{...attributes.popper}
				>
					<div
						className={classNames(
							"popper__arrow",
							`popper__arrow--${state?.placement}`
						)}
						ref={setArrowElement}
						style={styles.arrow}
					/>
					<div className="popper__body">{children}</div>
				</div>
			</>,
			document.body
		);
	}
	return null;
};

Popper.propTypes = {
	behavior: PropTypes.object,
	className: PropTypes.string,
	placement: PropTypes.string,
	modifiers: PropTypes.array,
	children: PropTypes.element,
};

export default Popper;

export { usePopperBehavior };
