function IconDeparting(props) {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 34.528" {...props}>
			<g id="Groupe_4775" transform="translate(-1584.973 -1251.587)">
				<g id="Groupe_4773" transform="translate(1584.973 1251.587)">
					<path
						fill="currentColor"
						d="M1602.956 1256.725l4.493 2.135a.623.623 0 0 0 .346.056.632.632 0 0 0 .16-.044l5.662-2.4a.747.747 0 0 0 .423-.761.737.737 0 0 0-.547-.673c-2.39-.535-6.937-1.206-10.526.3a.737.737 0 0 0-.427.683.767.767 0 0 0 .416.704z"
						transform="translate(-1592.01 -1252.719)"
					/>
					<path
						fill="currentColor"
						d="M1624.558 1253.079a.777.777 0 0 0-.2-.345 4.308 4.308 0 0 0-1.674-.9c-2.849-.927-7.448 1.049-7.637 1.13l-21.488 9.093-7.363-2.146a.627.627 0 0 0-.664.213l-.386.466a.81.81 0 0 0 .046 1.058l4.285 4.379-2.5 1.1a.7.7 0 0 0-.37.406.826.826 0 0 0 .008.582l.411 1.044a.707.707 0 0 0 .5.443 17.433 17.433 0 0 0 5.839.08 39.743 39.743 0 0 0 7.468-1.816l2.187-.908-2.042 10.042a.823.823 0 0 0 .078.546 2.154 2.154 0 0 0 1.745 1.127 2.113 2.113 0 0 0 .343-.017c1.247-.164 2.557-1.449 3.989-3.914.012-.022.023-.043.033-.065l5.351-11.659 10.591-4.579a3.033 3.033 0 0 0 1.654-1.9 5.159 5.159 0 0 0-.204-3.46z"
						transform="translate(-1584.973 -1251.587)"
					/>
				</g>
				<g id="Groupe_4774" transform="translate(1586.688 1284.616)">
					<path
						fill="currentColor"
						d="M1623.376 1308.188h-34.765a.75.75 0 1 1 0-1.5h34.765a.75.75 0 1 1 0 1.5z"
						transform="translate(-1587.835 -1306.689)"
					/>
				</g>
			</g>
		</svg>
	);
}

export default IconDeparting;
