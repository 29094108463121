import SidePanel from "../SidePanel/SidePanel";
import "./Drawer.scss";
import classNames from "classnames";

const Drawer = ({ children, className, ...props }) => {
	return (
		<SidePanel {...props}>
			<div className={classNames(className, "drawer")}>{children}</div>
		</SidePanel>
	);
};

export default Drawer;
