import PropTypes from "prop-types";
import { PureComponent } from "react";
import classNames from "classnames";

class DateSelectorItem extends PureComponent {
	constructor(props) {
		super(props);
		this.handleClick = this.handleClick.bind(this);
	}

	handleClick() {
		if (typeof this.props.onClick === "function") {
			this.props.onClick(this.props.value);
		}
	}

	render() {
		const { label, value, isActive, ...props } = this.props;

		const clazz = classNames({
			"date-selector__item": true,
			"date-selector__item--selected": isActive,
		});

		let itemLabel = label;

		if (typeof label === "function") {
			itemLabel = label(value);
		}

		return (
			<div className={clazz} {...props} onClick={this.handleClick}>
				{itemLabel}
			</div>
		);
	}
}

DateSelectorItem.propTypes = {
	isActive: PropTypes.bool,
	onClick: PropTypes.func.isRequired,
	value: PropTypes.any.isRequired,
	label: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number,
		PropTypes.element,
		PropTypes.func,
	]),
};

export default DateSelectorItem;
