import { memo, useRef, useCallback, useState, useContext } from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import { messagePropType } from "app/utils/propTypes";
import { useField } from "formik";
import "./AdvancedSelectFormik.scss";
import classNames from "classnames";
import AppGlobalsContext from "app/AppGlobalsContext";
import { RESOLUTION } from "app/pages/.shared/responsive/responsiveReducer";

import { Option, MenuList, Menu } from "./componentsOverrides";

const AdvancedSelectFormik = props => {
	const {
		id,
		label,
		className,
		isRequired,
		icon,
		keyCountMinToOpenMenu = 0,
		popperWidth = 200,
		...restProps
	} = props;
	const [field, meta, helpers] = useField(props);
	const { setValue, setTouched, setError } = helpers;

	const { resolution } = useContext(AppGlobalsContext);
	const isMobile = resolution === RESOLUTION.SMALL || resolution === RESOLUTION.MEDIUM;

	const inputRef = useRef();
	const mobileMenuWidth = inputRef?.current?.offsetWidth;

	const inputClassName = classNames("advanced-select", className, {
		"advanced-select--required": isRequired,
		"advanced-select--touched": (meta.touched && !meta.error) || field.value,
		"advanced-select--error": meta.touched && meta.error,
		"advanced-select--with-icon": icon,
	});

	const handleFocus = useCallback(() => {
		setTouched(true);
		setError();
	}, []);

	const setUntouchedOnBlur = useCallback(event => {
		if (event.target.value === "") {
			setTouched(false);
		}
	}, []);

	const handleChange = useCallback(option => {
		setValue(option);
	}, []);

	const [menuIsOpen, setMenuOpen] = useState(false);

	const handleInputChange = useCallback(values => {
		setMenuOpen(values.length >= keyCountMinToOpenMenu);
	}, []);

	if (keyCountMinToOpenMenu > 0) {
		restProps.menuIsOpen = menuIsOpen;
		restProps.onInputChange = handleInputChange;
	}

	return (
		<div
			ref={inputRef}
			className={inputClassName}
			data-testid={restProps["data-testid"]}
			id={id}
		>
			<Select
				isClearable={true}
				backspaceRemovesValue={true}
				className="advanced-select__select"
				classNamePrefix="advanced-select__select"
				styles={{
					dropdownIndicator: (base, state) => ({
						...base,
						transition: "all .2s ease",
						transform: state.selectProps.menuIsOpen ? "rotate(180deg)" : null,
					}),
					menuList: base => ({
						...base,
						minWidth: isMobile ? "none" : popperWidth,
						width: isMobile ? mobileMenuWidth : "unset",
						maxHeight: "175px",
						padding: "0",
					}), // on limite volontairement pour afficher la moitié du 4ème pour suggérer la possibilité de scroller pour afficher la suite
					option: base => ({
						...base,
						border: "none",
						height: "30px",
						display: "flex",
						alignItems: "center",
					}),
					menuPortal: provided => ({
						...provided,
						zIndex: 999,
						paddingTop: "20px",
					}),
					menu: provided => ({
						...provided,
						width: "fit-content",
					}),
				}}
				placeholder={null}
				blurInputOnSelect={true}
				captureMenuScroll={true}
				menuShouldScrollIntoView={true}
				{...field}
				onFocus={handleFocus}
				onBlur={setUntouchedOnBlur}
				onChange={handleChange}
				menuPortalTarget={typeof document !== "undefined" && document.body}
				components={{ Menu, MenuList, Option }}
				{...restProps}
			/>
			{icon && <div className="advanced-select__icon">{icon}</div>}
			<label htmlFor={id} className="advanced-select__label">
				{label}
			</label>
		</div>
	);
};

AdvancedSelectFormik.propTypes = {
	id: PropTypes.string,
	label: messagePropType,
	value: PropTypes.any,
	isSearchable: PropTypes.bool,
	isClearable: PropTypes.bool,
	getOptionLabel: PropTypes.func,
	getOptionValue: PropTypes.func,
	onChange: PropTypes.func,
	options: PropTypes.array,
	onMenuOpen: PropTypes.func,
	onMenuClose: PropTypes.func,
	isRequired: PropTypes.bool,
	icon: PropTypes.element,
	keyCountMinToOpenMenu: PropTypes.number,
	popperWidth: PropTypes.number,
};

export default memo(AdvancedSelectFormik);
