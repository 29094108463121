import PropTypes from "prop-types";
import { memo, useCallback, useEffect, useState } from "react";
import classNames from "classnames";
import { PASSENGER_TYPE } from "app/constants";
import DateSelector from "app/pages/.shared/DateSelector/DateSelector";
import "./DateSelectorInput.scss";
import { useIntl } from "react-intl";
import get from "lodash/get";
import isUndefined from "lodash/isUndefined";
import { useField } from "formik";
import { messagePropType } from "app/utils/propTypes";
import { Popper, usePopper } from "app/pages/.shared/Popper";

const DateSelectorInputFormik = props => {
	const {
		id,
		label,
		isRequired,
		disabled,
		formatMonth,
		name,
		type = PASSENGER_TYPE.ADULT,
		isChildrenAgeRequired,
		isHidden = false,
		onUpdate,
		isSubmitting,
	} = props;

	const [field, meta, helpers] = useField(props);
	const [day, setDay] = useState(field.value.day);
	const [month, setMonth] = useState(field.value.month);
	const [year, setYear] = useState(field.value.year);
	const [isDisabled, setDisabled] = useState(disabled);
	const { setValue } = helpers;

	const behavior = usePopper();

	useEffect(() => {
		if (isSubmitting) {
			behavior.handleClose();
		}
	}, [isSubmitting]);

	const intl = useIntl();

	const closePopover = useCallback(() => {
		setDisabled(false);
		behavior.handleClose();
	}, []);

	const showPopover = useCallback(e => {
		setDisabled(true);
		setValue({ day: undefined, month: undefined, year: undefined });
		setDay(undefined);
		setMonth(undefined);
		setYear(undefined);
		behavior.handleOpen(e);
	}, []);

	const handleCrossClick = useCallback(() => {
		if (isUndefined(day) || isUndefined(month) || isUndefined(year)) {
			setDay(undefined);
			setMonth(undefined);
			setYear(undefined);

			setValue({ day: undefined, month: undefined, year: undefined });
		}

		closePopover();
	}, [day, month, year]);

	const onDateChange = useCallback(date => {
		setDay(date.day);
		setMonth(date.month);
		setYear(date.year);

		if (date.year) {
			setDisabled(false);
			behavior.handleClose();

			setValue({
				...date,
			});

			onUpdate({ ...date });
		}
	}, []);

	const renderDate = useCallback(
		(day, month, year) => {
			const format = {};
			let renderDay;
			let renderMonth;
			let renderYear;

			if (day) {
				format.day = "2-digit";
				renderDay = day;
			} else {
				renderDay = 1;
			}

			if (month !== "" && month >= 0) {
				format.month = formatMonth;
				renderMonth = month;
			} else {
				renderMonth = 0;
			}

			if (year) {
				format.year = "numeric";
				renderYear = year;
			} else {
				// c'est une annee arbitraire ayant un 29 fevrier. prendre 1972 provoque un bug sur safari ou le jour est minoré de 1
				// voir https://github.com/yahoo/react-intl/issues/991
				renderYear = 1984;
			}

			if (day || month || year) {
				// on ne positionne pas sur un horaire de 00:00:00 pour éviter les switchs de date (ex: 31 mars 1969 serait repositionner en 30 mars 1969)
				return intl.formatDate(
					new Date(renderYear, renderMonth, renderDay, 15, 0, 0),
					format
				);
			}
			return new Date();
		},
		[formatMonth]
	);

	const isAnyDateValues = year || month || day;

	const isError =
		meta.error ||
		(get(field, "year.touched") &&
			get(field, "month.touched") &&
			get(field, "day.touched") &&
			get(field, "year.error") &&
			get(field, "month.error") &&
			get(field, "day.error"));

	const inputClassName = classNames({
		"control-group": true,
		"control-group--required": isRequired,
		"control-group--touched": isAnyDateValues,
		"control-group--error": isError,
		"control-group--disabled": isDisabled,
	});

	const thisYear = new Date().getFullYear();

	let maxYear = isChildrenAgeRequired ? thisYear - 18 : thisYear - 12;
	let minYear = 1900;

	if (type === PASSENGER_TYPE.CHILD) {
		// enfant entre 2 et 11 ans
		maxYear = thisYear - 1;
		minYear = isChildrenAgeRequired ? thisYear - 19 : thisYear - 13;
	} else if (type === PASSENGER_TYPE.INFANT) {
		// enfant entre 0 et 2 ans
		maxYear = thisYear;
		minYear = thisYear - 3;
	} else {
		maxYear = thisYear;
		minYear = isChildrenAgeRequired ? thisYear - 19 : thisYear - 13;
	}

	let computedValue;

	if (isAnyDateValues) {
		computedValue = renderDate(day, month, year);
	} else {
		computedValue = "";
	}

	return isHidden ? (
		false
	) : (
		<div className="date-selector-input" data-testid={props["data-testid"]}>
			<div className={inputClassName}>
				<label htmlFor={id} className="control-group__label">
					{label}*
				</label>

				<input
					{...field}
					className="control-group__input"
					type="text"
					id={id}
					name={name}
					value={computedValue}
					onFocus={showPopover}
					disabled={isDisabled}
					readOnly={true}
				/>
			</div>
			<Popper className="date-selector-input__popper" behavior={behavior}>
				<div className="date-selector-input__date-selector ignore-react-onclickoutside">
					<div className="date-selector-input__close">
						<i className="icon icon--cross-dark" onClick={handleCrossClick} />
					</div>
					<DateSelector maxYear={maxYear} minYear={minYear} onChange={onDateChange} />
				</div>
			</Popper>
		</div>
	);
};

DateSelectorInputFormik.propTypes = {
	name: PropTypes.string,
	label: messagePropType,
	id: PropTypes.string.isRequired,
	isChildrenAgeRequired: PropTypes.bool,
	onUpdate: PropTypes.func,
	disabled: PropTypes.bool,
	isHidden: PropTypes.bool,
	isRequired: PropTypes.bool,
	field: PropTypes.object,
	formatMonth: PropTypes.oneOf(["2-digit", "long"]),
	type: PropTypes.oneOf([PASSENGER_TYPE.CHILD, PASSENGER_TYPE.INFANT, PASSENGER_TYPE.ADULT]),
	"data-testid": PropTypes.string,
	isSubmitting: PropTypes.bool,
};

DateSelectorInputFormik.defaultProps = {
	formatMonth: "long",
	onUpdate: () => {},
};

export default memo(DateSelectorInputFormik);
