const IconLeft = props => (
	<svg viewBox="0 0 512 512" {...props}>
		<path
			fill="currentColor"
			d="M124.9,266.2l241.6,241.5c2.8,2.9,6.5,4.3,10.2,4.3c3.7,0,7.4-1.5,10.2-4.3c5.7-5.7,5.7-14.8,0-20.5L155.6,256 L386.9,24.8c5.7-5.7,5.7-14.8,0-20.5c-5.7-5.7-14.8-5.7-20.5,0L124.8,245.8C119.3,251.4,119.3,260.6,124.9,266.2z"
		/>
	</svg>
);

export default IconLeft;
